<template>
  <div style="margin: 20px; margin-left: 40px;">
    <div v-if="isTokenSet">
      <order-form :orderId="orderId" isOrderTopBar isViewOnly :orderType="orderType" propsView="VIEW_ORDER"
        :offlineOrganizationId="offlineOrganizationId"
      />
    </div>
  </div>
</template>

<script>
import OrderForm from '@/views/order/components/OrderForm'

export default {
  name: 'orderOffline',
  components: { OrderForm },
  data () {
    return {
      order: null,
      orderType: null,
      orderId: null,
      isTokenSet: false,
      offlineOrganizationId: null
    }
  },
  created () {
    this.$store.commit('setCurrentCompany', {})
    const uuid = this.$route.query.uuid
    this.getOrderIdTokenByUuid(uuid)
  },
  methods: {
    getOrderIdTokenByUuid (uuid) {
      this.$request.get({
        url: `${this.$apis.offlineOrder}/${uuid}`
      }).then(res => {
        this.orderId = decodeURIComponent(window.atob(res.data.orderId))
        const token = decodeURIComponent(window.atob(res.data.token))
        this.$store.commit('setToken', token)
        try {
          this.offlineOrganizationId = JSON.parse(window.atob(token.split('.')[1])).sysOrganizationId
        } catch (e) {
          return null
        }
        this.isTokenSet = true
      })
    }
  }
}
</script>
