var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { margin: "20px", "margin-left": "40px" } }, [
    _vm.isTokenSet
      ? _c(
          "div",
          [
            _c("order-form", {
              attrs: {
                orderId: _vm.orderId,
                isOrderTopBar: "",
                isViewOnly: "",
                orderType: _vm.orderType,
                propsView: "VIEW_ORDER",
                offlineOrganizationId: _vm.offlineOrganizationId,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }